import { NMAAHCPropTypes, Theme, ThemeContext } from "assets";
import { FormattedText } from "atoms";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./about.module.scss";

const InternalAbout = ({ title, longDescription, coverImage }) => {
  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout theme={Theme.Black} title={"About the searchable Museum"}>
        <div className={styles.ctnWrapper} data-testid="about-page">
          <div className={styles.imgCtn}>
            <GatsbyImage
              alt={coverImage?.altText}
              data-testid="about-page-image"
              image={getImage(coverImage)}
            />
          </div>
          <div className={styles.textCtn}>
            <FormattedText outerElement={<h1 />} text={title} />
            <FormattedText text={longDescription} />
          </div>
        </div>
      </PageLayout>
    </ThemeContext.Provider>
  );
};

InternalAbout.propTypes = {
  coverImage: NMAAHCPropTypes.Image,
  longDescription: PropTypes.string,
  title: PropTypes.string,
};

const About = (props) => {
  const aboutQuery = useStaticQuery(graphql`
    query {
      craftAPI {
        entry(type: "about") {
          ... on CraftAPI_about_about_Entry {
            id
            componentList {
              ...ComponentListFragment
            }
            coverImage {
              ...ImageMetadataFragment
            }
            coverImageCropStyle
            coverImagePositionOverride
            longDescription
            shortDescription
            title
          }
        }
      }
    }
  `);

  const aboutThisSiteData = aboutQuery?.craftAPI?.entry;

  return (
    <InternalAbout
      coverImage={aboutThisSiteData?.coverImage[0]?.imageFile}
      longDescription={aboutThisSiteData?.longDescription}
      title={aboutThisSiteData?.shortDescription}
    />
  );
};

export default About;
